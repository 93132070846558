import { toast } from 'react-toastify';
import LiveBlogModel from '../models/live-blog.model';
import HttpService from '../../../../services/rest/HttpService';
import { LiveBlogConfigurationDisabledSaveModel, LiveBlogTypes } from './utility.helper';
import { responseToLiveBlogModel } from '../models/live-blog.mapper';
import { responseToLiveBlogListingModel } from '../models/live-blog-listing.mapper';
import LiveBlogListingModel from '../models/live-blog-listing.model';
import AuthorModel from '../../../../models/v2/author/author.model';
import _ from 'lodash';
import MainMediaModel from '../../../../models/v2/main-media/main-media.model';
import TeamModel from '../../../../models/v2/Team/Entity/TeamModel';
import {
	LiveBlogPostCreatePayload,
	LiveBlogPostUpdatePayload,
	LiveBlogPostsFilter,
	LiveBlogPostsResponse,
} from '../models/live-blog-post.model';
import ListModel from '../../../../models/v2/list/list';
import Project from '../../../../models/project/Project';
import { addIdToListItemsOnCreate } from '../components/subcomponents/live-blog-lists/live-blog-lists.helper';

export const DUPLICATE_SLUG_ERROR = 'File is too big';
export const DUPLICATE_EVENT_ERROR = 'Configuration for Liveblog with project:';

const isResponseStatusOkay = (response: any) => response.status >= 200 && response.status < 300;

export const handleErrors = (error: string, t: any) => {
	if (error.includes(DUPLICATE_SLUG_ERROR)) {
		toast.error(t('duplicate_slug_error'));
	} else if (error.includes(DUPLICATE_EVENT_ERROR)) {
		toast.error(t('duplicate_event_error'));
	} else {
		return toast.error(error);
	}
};

const liveBlogDataErrorHandler = async (t: any, resp: any, response: any, backupSuccess: string, backupError: string) => {
	if (isResponseStatusOkay(resp)) {
		response.message ? toast.success(response.message) : toast.success(backupSuccess);
	} else {
		response.message ? handleErrors(response.message, t) : toast.error(backupError);
	}
};

const isLiveBlogConfigurationDataCorrect = (data: LiveBlogModel): boolean => {
	return !(!data.title || !data.main_media || !data.category || !data.start_time || !data.language || !data.slug);
};

const liveBlogDataToRequestData = (data: LiveBlogModel, userProfile: AuthorModel, type: string) => {
	try {
		let finalData = data;
		finalData.created_by = userProfile;
		// @ts-ignore
		const { permissions, ...userRest } = finalData.created_by;
		finalData.created_by = userRest;
		finalData.slug = finalData.slug && finalData.slug.length > 0 ? finalData.slug : null;
		delete finalData['main_media_type'];
		finalData.main_media = finalData.main_media.map((media: MainMediaModel) => {
			return camelCaseToSnakeCase(media);
		});

		if (type === LiveBlogTypes.MATCH) {
			finalData.teams =
				finalData.teams &&
				finalData.teams.map((team: TeamModel) => {
					return camelCaseToSnakeCase(team);
				});
			// @ts-ignore
			finalData.competitions[0].country =
				finalData.competitions &&
				finalData.competitions.map((competition: any) => {
					return camelCaseToSnakeCase(competition);
				});
		}
		finalData.created_by = camelCaseToSnakeCase(finalData.created_by);
		finalData.category = camelCaseToSnakeCase(finalData.category);
		finalData.additional_categories =
			finalData.additional_categories &&
			finalData.additional_categories.map((category: any) => {
				return camelCaseToSnakeCase(category);
			});
		finalData.collaborators =
			finalData.collaborators &&
			finalData.collaborators.map((collaborator: any) => {
				return camelCaseToSnakeCase(collaborator);
			});
		finalData.authors =
			finalData.authors &&
			finalData.authors.map((author: any) => {
				return camelCaseToSnakeCase(author);
			});

		return finalData;
	} catch (e) {
		return LiveBlogModel;
	}
};

function camelCaseToSnakeCase(obj: any) {
	if (obj === null || typeof obj !== 'object') {
		return obj;
	}

	const result = {};
	for (const key in obj) {
		if (obj.hasOwnProperty(key)) {
			const snakeCaseKey = _.snakeCase(key);
			result[snakeCaseKey] = camelCaseToSnakeCase(obj[key]);
		}
	}

	return result;
}

function snakeCaseCamelCase(obj: any) {
	if (obj === null || typeof obj !== 'object') {
		return obj;
	}

	const result = {};
	for (const key in obj) {
		if (obj.hasOwnProperty(key)) {
			const snakeCaseKey = _.camelCase(key);
			result[snakeCaseKey] = snakeCaseCamelCase(obj[key]);
		}
	}

	return result;
}

export const createLiveBlog = async (
	t: any,
	data: LiveBlogModel,
	history: any,
	successMessage: string = '',
	errorMessage: string = '',
	userProfile: AuthorModel,
	type: string,
	selectedList: ListModel,
	updateListWithPatchRequest: (list: ListModel, project: Project) => void,
	projectDomain: Project,
	isLoading: (loading: boolean) => void,
	resetListInRedux: Function,
) => {
	if (isLiveBlogConfigurationDataCorrect(data)) {
		isLoading(true);
		const necessaryData = liveBlogDataToRequestData(data, userProfile, type);
		const response = await HttpService.createLiveBlog(necessaryData);
		const responseJSON = (await response.json()) || null;
		const listData = addIdToListItemsOnCreate(selectedList, responseJSON.id);
		listData && updateListWithPatchRequest(listData, projectDomain);
		isResponseStatusOkay(response) && history.push(`/smp/live-blogs/editorial-admin/${responseJSON.id}`);
		isLoading(false);
		resetListInRedux();
		await liveBlogDataErrorHandler(t, response, responseJSON, successMessage, errorMessage);
	} else {
		isLoading(false);
		toast.error(errorMessage);
	}
	isLoading(false);
};

export const updateLiveBlog = async (
	t: any,
	data: LiveBlogModel,
	setDisabledSave: Function,
	setLiveBlog: Function,
	successMessage: string = '',
	errorMessage: string = '',
	userProfile: AuthorModel,
	type: string,
	isLoading: (loading: boolean) => void,
) => {
	try {
		if (isLiveBlogConfigurationDataCorrect(data)) {
			isLoading(true);
			const id = data.id;
			const necessaryData = liveBlogDataToRequestData(data, userProfile, type);
			const response = await HttpService.updateLiveBlogById(id, necessaryData);
			const responseJSON = (await response.json()) || null;
			await liveBlogDataErrorHandler(t, response, responseJSON, successMessage, errorMessage);
			if (isResponseStatusOkay(response)) {
				const updatedLiveBlog = Object.assign({}, necessaryData) as LiveBlogModel;
				updatedLiveBlog.main_media = updatedLiveBlog.main_media.map((media: MainMediaModel) => {
					return snakeCaseCamelCase(media);
				});
				isLoading(false);
				setLiveBlog(updatedLiveBlog);
				setDisabledSave(new LiveBlogConfigurationDisabledSaveModel());
			}
		} else {
			isLoading(false);
			toast.error(errorMessage);
		}
	} catch (e) {
		isLoading(false);
		errorMessage && toast.error(errorMessage);
	}
	isLoading(false);
};

export const getLiveBlogById = async (id: string, errorMessage: string = '') => {
	try {
		const response = await HttpService.getLiveBlogById(id);
		let data = (await response.data) as LiveBlogModel;
		return data.id ? responseToLiveBlogModel(data) : new LiveBlogModel();
	} catch (e) {
		errorMessage && toast.error(errorMessage);
		return new LiveBlogModel();
	}
};

export const getLiveBlogs = (errorMessage: string = '') => {
	try {
		const responseArray = HttpService.getAllLiveBlogs();
		let data = responseArray.data as LiveBlogListingModel;
		if (data) {
			return responseToLiveBlogListingModel(data);
		}
		return {} as LiveBlogListingModel;
	} catch (e) {
		errorMessage && toast.error(errorMessage);
		return {} as LiveBlogListingModel;
	}
};

export const createNewLiveBlogPost = async (
	id: string,
	data: LiveBlogPostCreatePayload,
	successMessage: string = '',
	errorMessage: string = '',
) => {
	try {
		await HttpService.createNewLiveBlogPost(id, data);
		successMessage && toast.success(successMessage);
	} catch (e) {
		errorMessage && toast.error(errorMessage);
		throw new Error(errorMessage);
	}
};

export const editLiveBlogPost = async (
	liveBlogId: string,
	postId: string,
	data: LiveBlogPostUpdatePayload,
	successMessage: string = '',
	errorMessage: string = '',
) => {
	try {
		await HttpService.editLiveBlogPost(liveBlogId, postId, data);
		successMessage && toast.success(successMessage);
	} catch (e) {
		errorMessage && toast.error(errorMessage);
		throw new Error(errorMessage);
	}
};

export const deleteLiveBlogPost = async (liveBlogId: string, postId: string, successMessage: string = '', errorMessage: string = '') => {
	try {
		await HttpService.deleteLiveBlogPost(liveBlogId, postId);
		successMessage && toast.success(successMessage);
	} catch (e) {
		errorMessage && toast.error(errorMessage);
		throw new Error(errorMessage);
	}
};

export const getLiveBlogPosts = async (
	id: string,
	params: LiveBlogPostsFilter,
	errorMessage: string = '',
): Promise<LiveBlogPostsResponse> => {
	try {
		const response = await HttpService.getLiveBlogPosts(id, params);

		return response.data;
	} catch (e) {
		errorMessage && toast.error(errorMessage);
		throw new Error(errorMessage);
	}
};
