import React, { FunctionComponent, useEffect, useState } from 'react';
import LiveBlogModel from '../../models/live-blog.model';
import '../../style/live-blog-listing.style.scss';
import '../../style/live-blog.style.scss';
import { LiveBlogConfigurationDisabledSaveModel } from '../../helpers/utility.helper';
import LiveBlogConfigurationContainer from '../configuration/live-blog-configuration.container';

type Properties = {
	t: any;
	history: any;
};

export const LiveBlogCreate: FunctionComponent<Properties> = ({ t, history }) => {
	const createDisabledSaveModel = new LiveBlogConfigurationDisabledSaveModel();
	createDisabledSaveModel.validLiveBlogTitle = false;
	createDisabledSaveModel.validLiveBlogCategory = false;
	createDisabledSaveModel.validLiveBlogStartTime = false;
	createDisabledSaveModel.validLiveBlogEndTime = false;
	createDisabledSaveModel.validLiveBlogMainMedia = false;
	createDisabledSaveModel.validLiveBlogSport = false;
	createDisabledSaveModel.validLiveBlogTournament = false;
	createDisabledSaveModel.validLiveBlogEvents = false;

	const [disabledSave, setDisabledSave] = useState(createDisabledSaveModel);
	const [liveBlog, setLiveBlog] = useState<LiveBlogModel>(new LiveBlogModel());

	useEffect(() => {
		initPageTitle();
	}, []);

	const initPageTitle = () => {
		document.title = t('live_blog_create');
	};

	return (
		<>
			<LiveBlogConfigurationContainer
				isNewLiveBlog={true}
				history={history}
				t={t}
				liveBlog={liveBlog}
				setLiveBlog={setLiveBlog}
				disabledSave={disabledSave}
				setDisabledSave={setDisabledSave}
			/>
		</>
	);
};
