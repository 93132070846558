import React, { FunctionComponent, useState } from 'react';
import { ContentTypes } from '../../../../../constants/content-types';
import LiveBlogModel from '../../models/live-blog.model';
import '../../style/live-blog.style.scss';
import {
	determineConfigurationStage,
	handleStepperChange,
	LiveBlogConfigurationDisabledSaveModel,
	LiveBlogTypes,
	shouldSaveLiveBlogNextButtonBeDisabled,
} from '../../helpers/utility.helper';
import { Button, Col, InputGroupAddon, Row } from 'reactstrap';
import { LiveBlogMainConfigurationComponent } from './live-blog-main-configuration.component';
import { LiveBlogSportsConfigurationComponent } from './live-blog-sports-configuration.component';
import { LiveBlogAdditionalConfigurationComponent } from './live-blog-additional-configuration.component';
import { createLiveBlog, updateLiveBlog } from '../../helpers/live-blog-main.helper';
import { StepperComponent } from '../stepper/stepper.component';
import { connect, useSelector } from 'react-redux';
import { AppState } from '../../../../../store/store';
import ListModel from '../../../../../models/v2/list/list';
import Project from '../../../../../models/project/Project';
import { returnObjectForListEntityUpdate, updateListInRedux } from '../../../../../store/action-creators/ListActionCreators';
import { isListEmpty } from '../../../../Partials/Sidebar/list-refactored/helpers/general.helper';

type Properties = {
	t: any;
	history: any;
	isNewLiveBlog: boolean;
	liveBlog: LiveBlogModel;
	setLiveBlog: any;
	disabledSave: LiveBlogConfigurationDisabledSaveModel;
	setDisabledSave: any;
	selectedList: ListModel;
	updateListWithPatchRequest: (list: ListModel, project: Project) => void;
	projectDomain: Project;
	resetListInRedux: Function;
};

const LiveBlogConfigurationContainer: FunctionComponent<Properties> = ({
	t,
	history,
	isNewLiveBlog,
	liveBlog,
	setLiveBlog,
	disabledSave,
	setDisabledSave,
	selectedList,
	updateListWithPatchRequest,
	projectDomain,
	resetListInRedux,
}) => {
	const [selectedPage, setSelectedPage] = useState(new Array(4).fill(1));
	const [isRequestInProgress, setIsRequestInProgress] = useState<boolean>(false);
	const userProfile = useSelector((state: AppState) => state.profile.profile);

	const changePage = (pagePane: any, page: any) => {
		const newArray = selectedPage.slice();
		newArray[pagePane] = page;
		setSelectedPage(newArray);
	};

	const handleLiveBlogSave = () => {
		isNewLiveBlog
			? createLiveBlog(
					t,
					liveBlog,
					history,
					t('live_blog_create_success'),
					t('error_creating_live_blog'),
					userProfile,
					liveBlog.type,
					selectedList,
					updateListWithPatchRequest,
					projectDomain,
					setIsRequestInProgress,
					resetListInRedux,
			  )
			: updateLiveBlog(
					t,
					liveBlog,
					setDisabledSave,
					setLiveBlog,
					t('live_blog_update_success'),
					t('error_updating_live_blog'),
					userProfile,
					liveBlog.type,
					setIsRequestInProgress,
			  );
	};

	const handleLiveBlogStepperChange = (type: string | null, isNewLiveBlog: boolean, page: number) => {
		handleStepperChange(type, isNewLiveBlog, page, disabledSave, changePage);
	};

	return (
		<div className='animated fadeIn' data-qa={`${ContentTypes.LIVE_BLOG}-configuration`}>
			<div className='container'>
				<StepperComponent
					redirect={(page) => handleLiveBlogStepperChange(liveBlog.type, isNewLiveBlog, page)}
					type={liveBlog.type ? liveBlog.type : LiveBlogTypes.MATCH}
					activeStep={selectedPage[0]}
				/>
			</div>
			<Row>
				<Col col='12' lg='12' md='12' sm='12' xs='12'>
					<div className='card'>
						<div className='card-body live-blog-container'>
							{selectedPage[0] === 1 && (
								<Row>
									<span className='divider-configuration' />
									<LiveBlogMainConfigurationComponent
										disabledSave={disabledSave}
										liveBlog={liveBlog}
										setLiveBlog={setLiveBlog}
										setDisabledSave={setDisabledSave}
										t={t}
									/>
								</Row>
							)}
							{selectedPage[0] === 2 && liveBlog.type === LiveBlogTypes.MATCH ? (
								<Row>
									<span className='divider-configuration' />
									<LiveBlogSportsConfigurationComponent
										liveBlog={liveBlog}
										setLiveBlog={setLiveBlog}
										t={t}
										setDisabledSave={setDisabledSave}
										disabledSave={disabledSave}
									/>
								</Row>
							) : (
								selectedPage[0] === 2 &&
								liveBlog.type === LiveBlogTypes.LIVE_NEWS && (
									<Row>
										<span className='divider-configuration' />
										<LiveBlogAdditionalConfigurationComponent liveBlog={liveBlog} setLiveBlog={setLiveBlog} t={t} />
									</Row>
								)
							)}
							{selectedPage[0] === 3 && liveBlog.type === LiveBlogTypes.MATCH && (
								<Row>
									<span className='divider-configuration' />
									<LiveBlogAdditionalConfigurationComponent liveBlog={liveBlog} setLiveBlog={setLiveBlog} t={t} />
								</Row>
							)}
						</div>
					</div>
				</Col>
			</Row>
			<div className='live-blog-buttons'>
				<InputGroupAddon addonType='append'>
					<Button type='button' bssize='sm' onClick={() => changePage(0, selectedPage[0] - 1)} disabled={selectedPage[0] === 1}>
						{t('previous')}
					</Button>
					{determineConfigurationStage(
						t,
						liveBlog.type,
						selectedPage,
						changePage,
						handleLiveBlogSave,
						shouldSaveLiveBlogNextButtonBeDisabled,
						disabledSave,
						isNewLiveBlog,
						liveBlog,
						isRequestInProgress,
					)}
				</InputGroupAddon>
			</div>
		</div>
	);
};

function mapStateToProps(state: any) {
	return {
		projectDomain: state.project.currentProject || '',
		selectedList: (!isListEmpty(state.list.list) && state.list.list) || null,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		updateListWithPatchRequest: (list: ListModel, project: Project) => dispatch(returnObjectForListEntityUpdate(list, project)),
		resetListInRedux: () => dispatch(updateListInRedux(null)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveBlogConfigurationContainer);
