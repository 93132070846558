import React from 'react';
import { Col, FormGroup, Row, Input, Label, FormFeedback } from 'reactstrap';
import { blockWrapperService, blockManagementService } from '../../../../subcomponents/blocky.component';
import TournamentModel from '../../player-profile/models/tournament-model/tournament.model';
import TournamentSelectContainer from '../../../../partials/tournament-select/tournament-select-container.component';
import SeasonSelectContainer from '../../../../partials/season-select/season-select-container.component';
import SeasonModel from '../../player-profile/models/season/season.model';
import BlockValidation from '../../../../helpers/block-validation.helper';
import ErrorHandler from '../../../../partials/error/error-handler-component';
import OffsetInput from '../../../../partials/offset-input/offset-input.component';
import DecoratedPlayersWidgetModel from '../models/decorated-players-widget.model';
import BlockModel from '../../../../models/block.model';
import PlayerModel from '../models/player/player.model';
import { MostDecoratedPlayerSelectContainer } from '../../../../partials/most-decorated-player-select/most-decorated-player-select-container.component';
import HttpService from '../../../../../../../services/rest/HttpService';
import { remapPlayersFromResponse } from './helpers/most-decorated-players.helper';
import { ColumnSelectContainer } from '../../../../partials/columns-select/columns-select-container.component';
import { PLAYERS_CARDS } from '../../../../constants/most-decorated-players.constants';
import ThreeLetterCodeTypeSelect from '../../../../partials/three-letter-code-type-select/three-letter-code-type-select';

export type Properties = {
	block: BlockModel;
	t: any;
};

export type State = {
	decoratedPlayers: DecoratedPlayersWidgetModel;
	isValid: boolean;
};

export default class MostDecoratedPlayersEdit extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			decoratedPlayers:
				props.block && props.block.data.preview && props.block.data.preview.decoratedPlayers
					? DecoratedPlayersWidgetModel.builder(props.block.data.preview.decoratedPlayers).build()
					: DecoratedPlayersWidgetModel.builder().build(),
			isValid: true,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	onSave = (index: number) => {
		const validation = new BlockValidation();
		const data = { decoratedPlayers: this.state.decoratedPlayers };
		const isValid = validation.validate(this.props.block, data);

		this.setState({ ...this.state, isValid });

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.preview = { decoratedPlayers: this.state.decoratedPlayers };
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	updateWidgetModelState = (model: DecoratedPlayersWidgetModel) => {
		this.setState({ ...this.state, decoratedPlayers: model });
	};

	onTournamentSelect = (tournament: TournamentModel) => {
		this.updateWidgetModelState(
			DecoratedPlayersWidgetModel.builder(this.state.decoratedPlayers)
				.withTournament(TournamentModel.builder(tournament).build())
				.withSeason(SeasonModel.builder().build())
				.withHighlightPlayers([])
				.withPlayer(PlayerModel.builder().build())
				.withOffset('')
				.withStartFrom('')
				.build(),
		);
	};

	onSeasonSelect = (season: SeasonModel) => {
		this.getSeasonDecoratedPlayers(season);
	};

	onLimitChange = (limit: string) => {
		this.updateWidgetModelState(DecoratedPlayersWidgetModel.builder(this.state.decoratedPlayers).withLimit(limit).build());
	};

	onStartPositionChange = (startFrom: string) => {
		this.updateWidgetModelState(DecoratedPlayersWidgetModel.builder(this.state.decoratedPlayers).withStartFrom(startFrom).build());
	};

	onHighlightPlayersOptions = (players: any[]) => {
		this.updateWidgetModelState(DecoratedPlayersWidgetModel.builder(this.state.decoratedPlayers).withHighlightPlayers(players).build());
	};

	onColumnsOptions = (columns: string[]) => {
		this.updateWidgetModelState(DecoratedPlayersWidgetModel.builder(this.state.decoratedPlayers).withColumns(columns).build());
	};

	onPlayerChange = (player: PlayerModel) => {
		this.updateWidgetModelState(
			DecoratedPlayersWidgetModel.builder(this.state.decoratedPlayers).withPlayer(player).withStartFrom('').withLimit('').build(),
		);
	};

	onOffsetChange = (playerOffset: string) => {
		this.updateWidgetModelState(
			DecoratedPlayersWidgetModel.builder(this.state.decoratedPlayers).withPlayerOffset(playerOffset).withStartFrom('').withLimit('').build(),
		);
	};

	getSeasonDecoratedPlayers = (season: SeasonModel) => {
		HttpService.instanceFootball()
			.get(`/tournaments/seasons/${season.id}/cardlist`)
			.then((response: any) => {
				const players = remapPlayersFromResponse(response.data);
				this.updateWidgetModelState(
					DecoratedPlayersWidgetModel.builder(this.state.decoratedPlayers)
						.withSeason(season)
						.withHighlightPlayers([])
						.withPlayer(PlayerModel.builder().build())
						.withPlayers(players)
						.withOffset('')
						.withStartFrom('')
						.build(),
				);
			})
			.catch((e: any) => e);
	};

	onThreeLetterCodeTypeChange = (value: any) => {
		this.updateWidgetModelState(DecoratedPlayersWidgetModel.builder(this.state.decoratedPlayers).withThreeLetterCodeType(value).build());
	};

	render() {
		const { decoratedPlayers, isValid } = this.state;
		const placeholder =
			decoratedPlayers.players && decoratedPlayers.players.length
				? `${this.props.t('from')} 1 ${this.props.t('to')} ${decoratedPlayers.players.length}`
				: `1, 4, 7, ${this.props.t('etc')}`;

		return (
			<>
				<Row>
					<Col>
						<FormGroup>
							<TournamentSelectContainer
								withLabel
								isBlocky
								isValid={isValid}
								selectedTournament={decoratedPlayers.tournament}
								onTournamentSelect={this.onTournamentSelect}
								t={this.props.t}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup>
							<SeasonSelectContainer
								withLabel
								isValid={isValid}
								selectedSeason={decoratedPlayers.season}
								tournamentId={decoratedPlayers.tournament && decoratedPlayers.tournament.id ? decoratedPlayers.tournament.id : ''}
								onSeasonSelect={this.onSeasonSelect}
								t={this.props.t}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup>
							<ColumnSelectContainer
								value={this.state.decoratedPlayers.columns}
								onColumnsSelect={this.onColumnsOptions}
								t={this.props.t}
								isMulti={true}
								columns={PLAYERS_CARDS}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup>
							<MostDecoratedPlayerSelectContainer
								value={this.state.decoratedPlayers.highlightPlayers}
								onPlayersSelect={this.onHighlightPlayersOptions}
								t={this.props.t}
								isMulti={true}
								decoratedPlayers={this.state.decoratedPlayers}
							/>
						</FormGroup>
					</Col>
				</Row>
				{!isValid && (!decoratedPlayers.players || (decoratedPlayers.players && decoratedPlayers.players.length === 0)) && (
					<div className='pb-2'>
						<ErrorHandler errorMessage='no_available_players' />
					</div>
				)}
				<ThreeLetterCodeTypeSelect
					t={this.props.t}
					isClearable={true}
					onThreeLetterCodeTypeChange={this.onThreeLetterCodeTypeChange}
					selectedThreeLetterCodeType={decoratedPlayers.threeLetterCodeType}
				/>
				<Row className='mb-1'>
					<Col>
						<hr />
						<h6>{this.props.t('preview_details')}</h6>
					</Col>
				</Row>
				<Row>
					<Col>
						{/*{//TODO Create reusable StartFromPosition component}*/}
						<FormGroup>
							<Label>{this.props.t('start_from_position')}:</Label>
							<Input
								type='number'
								disabled={
									(decoratedPlayers.player && !!decoratedPlayers.player.id) ||
									!!decoratedPlayers.playerOffset ||
									(decoratedPlayers && decoratedPlayers.players && decoratedPlayers.players.length === 0)
								}
								className='form-control'
								value={decoratedPlayers.startFrom ? decoratedPlayers.startFrom : ''}
								min={1}
								placeholder={placeholder}
								max={decoratedPlayers && decoratedPlayers.players && decoratedPlayers.players.length}
								onChange={(event: any) => this.onStartPositionChange(event.target.value)}
								invalid={
									decoratedPlayers &&
									decoratedPlayers.players &&
									(decoratedPlayers.players.length < +decoratedPlayers.startFrom ||
										(decoratedPlayers.startFrom !== '' && +decoratedPlayers.startFrom <= 0))
								}
							/>
							{decoratedPlayers && decoratedPlayers.players ? (
								<FormFeedback>
									{decoratedPlayers.players.length < +decoratedPlayers.startFrom
										? `${this.props.t('must_be_less_than', { playersLength: decoratedPlayers.players.length + 1 })}`
										: ''}
									{decoratedPlayers.startFrom !== '' && +decoratedPlayers.startFrom <= 0
										? `${this.props.t('must_be_greater_than', { length: '0' })}`
										: ''}
								</FormFeedback>
							) : null}
						</FormGroup>
					</Col>
					<Col>
						<FormGroup>
							<Label>{this.props.t('show')}:</Label>
							<Input
								type='number'
								disabled={(decoratedPlayers.player && !!decoratedPlayers.player.id) || !!decoratedPlayers.playerOffset}
								className='form-control'
								value={decoratedPlayers.limit ? decoratedPlayers.limit : ''}
								onChange={(event: any) => this.onLimitChange(event.target.value)}
								placeholder={this.props.t('players_in_most_decorated_players')}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row className='mb-2'>
					<Col>
						<MostDecoratedPlayerSelectContainer
							value={this.state.decoratedPlayers.player}
							onPlayersSelect={this.onPlayerChange}
							t={this.props.t}
							isMulti={false}
							decoratedPlayers={this.state.decoratedPlayers}
						/>
					</Col>
					<Col>
						<OffsetInput
							t={this.props.t}
							onOffsetChange={this.onOffsetChange}
							value={this.state.decoratedPlayers.playerOffset}
							item={this.state.decoratedPlayers && this.state.decoratedPlayers.player}
							type='most-decorated-players'
						/>
					</Col>
				</Row>
			</>
		);
	}
}
